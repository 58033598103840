var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
      on: {
        click: function ($event) {
          return _vm.$refs.receiverName.focus()
        },
      },
    },
    [
      _c("input", {
        ref: "receiverName",
        staticClass: "card-input__input brand-secondary brand-primary-focus",
        attrs: {
          id: "receiverName",
          placeholder: _vm.$t("FORM.COMMON.RECEIVER_NAME"),
          "data-card-field": "",
          maxlength: "256",
          minlength: "3",
          name: "receiverName",
          required: "",
          type: "text",
        },
        domProps: { value: _vm.inputValue },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changeCardHolder($event.target.value)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }