var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { flex: "1" } },
    [
      _c(
        "form",
        { ref: "dataForm" },
        [
          _c(
            "MultibancoDestinationData",
            _vm._b(
              { ref: "formComponent", tag: "component" },
              "component",
              { formData: _vm.formData },
              false
            )
          ),
          _c("input", {
            ref: "dataFormSubmit",
            staticStyle: { display: "none" },
            attrs: { type: "submit" },
          }),
          _vm.error
            ? _c("div", { staticClass: "error-label brand-error" }, [
                _c("span", { staticClass: "c-error-line" }, [
                  _vm._v(_vm._s(_vm.$t(_vm.error))),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "panel-footer" }, [
            _c("div", { staticClass: "panel-footer-submit" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button-pay button-accent brand-button-accent  brand-button-border-radius",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [[_vm._v(" " + _vm._s(_vm.$t("FORM.NEXT")) + " ")]],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("FormTimer", {
        attrs: { options: _vm.timerOptions },
        on: { expired: _vm.expired },
      }),
      _c("div", { staticClass: "panel-footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }