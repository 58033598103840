import { render, staticRenderFns } from "./MultibancoDestinationInfo.vue?vue&type=template&id=751bbee7&scoped=true"
import script from "./MultibancoDestinationInfo.vue?vue&type=script&lang=ts"
export * from "./MultibancoDestinationInfo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "751bbee7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/bda939a141d38e33/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('751bbee7')) {
      api.createRecord('751bbee7', component.options)
    } else {
      api.reload('751bbee7', component.options)
    }
    module.hot.accept("./MultibancoDestinationInfo.vue?vue&type=template&id=751bbee7&scoped=true", function () {
      api.rerender('751bbee7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/pay/steps/forms/multibanco/MultibancoDestinationInfo.vue"
export default component.exports