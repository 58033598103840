var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "modal-wrapper no-select" }, [
        _c(
          "div",
          {
            staticClass:
              "modal-container brand-header-border-radius brand-primary",
          },
          [
            _vm.header
              ? _c("div", { staticClass: "modal-header" }, [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.header) } }),
                ])
              : _vm._e(),
            _vm.body
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.body) } }),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "modal-footer brand-secondary brand-secondary-bb",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.submitForm.apply(null, arguments)
                  },
                },
              },
              [
                _c("button", { staticClass: "modal-button" }, [
                  _vm._v(" " + _vm._s(_vm.$t("FORM.CONFIRM")) + " "),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }