<template>
  <FormContent>
    <label>
      <b>{{ $t('FORM.P2P_DESCRIPTION_COMMON') }}</b>
    </label>
    <div v-for="field in formData.additionalParams.fields" :key="field.key">
        <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
             v-bind:class="{'brand-error-container': invalid}">
          <input
              :value="additionalField.value"
              readonly
              class="card-input__input brand-secondary brand-primary-focus"
          />
          <div class="c-info-icon" @click="copy(additionalField.value)">
            <img :src="require(`@/assets/svg/copy.svg`)"/>
          </div>
        </div>
    </div>
  </FormContent>
</template>

<script lang="ts">
import {Component, Prop} from 'vue-property-decorator';
import DataFormBase from "@/components/form/pay/steps/forms/DataFormBase";
import {IForm} from "@/components/form/pay/steps/forms/IForm";
import TextInputFormField from "@/components/form/common/form/fields/TextInputFormField.vue";
import FormField from "@/components/form/common/form/FormField.vue";
import FormContent from "@/components/form/common/form/layout/FormContent.vue";
import FormRow from "@/components/form/common/form/layout/FormRow.vue";
import FormCol from "@/components/form/common/form/layout/FormCol.vue";
import Modal from "@/components/common/Modal.vue";
import AdditionalFieldBase = com.paidora.billing.types.models.core.additional.info.steps.AdditionalFieldBase;

@Component(
    {
      components: {
        TextInputFormField,
        FormField,
        FormContent,
        FormRow,
        FormCol,
        Modal
      },
      directives: {}
    })

export default class AdditionalStepShowInfoForm extends DataFormBase implements IForm {
  @Prop()
  additionalField!: AdditionalFieldBase;
  constructor() {
    super();
  }

  copy(item: any) {
    window.navigator.clipboard.writeText(item);
  }

  get amountForCopy(): string {
    return Intl.NumberFormat(this.$i18n.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.amount).replace(',', '');
  }

}
</script>
<style lang="scss" scoped>
</style>
