<template>
  <div>
    <div class="method-type-select_label">{{ $t('FORM.SELECT_METHOD_TYPE') }}</div>
    <P2PMethodTypeButton v-bind:type="methodType" v-bind:key="methodType" v-for="methodType in formData.serviceMethodTypes" @method-type-selected="selectMethodType"></P2PMethodTypeButton>
    <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import FormSubmitRequest = com.paidora.billing.app.form.requests.FormSubmitRequest;
import P2PMethodTypeButton from "@/components/form/pay/steps/types/P2PMethodTypeButton.vue";

@Component({
  components: {
    P2PMethodTypeButton,
    FormTimer
  }
})
export default class P2PMethodTypes extends PayStepBase {
  submitting = false;

  mounted(): void {
    this.startExpirationTimer();
  }

  protected selectMethodType(methodType: string) {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    const request: FormSubmitRequest = {
      values: {},
      type: 'METHOD_TYPE',
      browserInfo: <any>null,
      method: 'P2P',
      methodType: methodType,
      additionalParams: <any>null
    };
    this.$emit('on-submit', this.formData.token, request);
  }
}
</script>

<style scoped lang="scss">
.method-type-select_label {
  display: flex;
  font-size: 14px;
  margin: 20px 40px;
  justify-content: center;
}
</style>
