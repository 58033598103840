var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-root" },
    [
      _c("div", { staticClass: "panel panel-default form-panel" }, [
        _c("div", { staticClass: "payouts-destination-header no-select" }, [
          _vm._v(_vm._s(_vm.$t("TEST.TITLE"))),
        ]),
        _c("div", { staticClass: "panel-body" }, [
          _c(
            "form",
            {
              ref: "paymentDataForm",
              attrs: {
                id: "paymentDataForm",
                action: _vm.termUrl,
                method: "post",
                novalidate: "",
              },
            },
            [
              _c(
                "FormContent",
                [
                  _c(
                    "FormRow",
                    [
                      _c(
                        "FormCol",
                        [
                          _c(
                            "FormField",
                            [
                              _c("OtpInputFormField", {
                                attrs: { "input-name": "PaRes" },
                                on: {
                                  changed: function ($event) {
                                    return _vm.changeOtp($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("input", {
                attrs: { name: "MD", type: "hidden" },
                domProps: { value: _vm.MD },
              }),
              _c("input", {
                ref: "payoutDataFormSubmit",
                staticStyle: { display: "none" },
                attrs: { type: "submit" },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "panel-footer-submit" }, [
            _c(
              "button",
              {
                staticClass:
                  "button-pay button-accent brand-button-accent brand-button-border-radius",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("TEST.PAY")) + " ")]
            ),
          ]),
        ]),
      ]),
      _c("FormFooter", {
        attrs: { brand: _vm.brandInfo, showCardIcons: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }