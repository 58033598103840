export function getHslFromRGBNumbers(r: number, g: number, b: number): { h: number, s: number, l: number } {

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;
  let
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0)
    h = 0;
  else if (cmax == r)
    h = ((g - b) / delta) % 6;
  else if (cmax == g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0)
    h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {h: h, s: s, l: l};
  // 'hsl(' + h + ',' + s + '%,' + l + '%)';
}

export function getHslFromRgbString(str: string): { h: number, s: number, l: number } {
  const match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d\.\d?)\))?/);
  if(match) {
    return getHslFromRGBNumbers(Number(match[1]), Number(match[2]), Number(match[3]));
  } else {
    return {
      h: 0, s: 0, l: 0
    };
  }
}
