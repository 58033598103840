var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
    },
    [
      _c("VuePhoneNumberInput", {
        ref: "phoneInput",
        attrs: {
          error: _vm.invalid,
          translations: {
            countrySelectorLabel: _vm.$t("FORM.MSISDN.COUNTRY_CODE"),
            countrySelectorError: _vm.$t("FORM.MSISDN.COUNTRY_CODE_ERROR"),
            phoneNumberLabel: _vm.$t("FORM.MSISDN.LABEL"),
            example: _vm.$t("FORM.MSISDN.EXAMPLE"),
          },
          required: "",
          "default-country-code": _vm.countryIsoCode,
        },
        on: {
          update: function ($event) {
            return _vm.changeMsisdn($event)
          },
        },
        model: {
          value: _vm.msisdnModel,
          callback: function ($$v) {
            _vm.msisdnModel = $$v
          },
          expression: "msisdnModel",
        },
      }),
      _c("input", {
        attrs: { name: _vm.inputName || "msisdn", type: "hidden" },
        domProps: { value: _vm.inputValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }