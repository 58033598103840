<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import {FormTimeOptions} from '@/components/form/pay/FormTimer.vue';
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;

@Component
export default class PayStepBase extends Vue {
  @Prop() formData!: PaymentFormInfo;
  error = '';
  data?: PaymentFormInfo;
  timerOptions: FormTimeOptions = {};

  @Emit('form-data-changed')
  formDataChanged(formData: PaymentFormInfo) {
    return formData;
  }

  @Emit('expired')
  expired(): boolean {
    return true;
  }

  protected startExpirationTimer() {
    const now = new Date();
    if (!this.timerOptions.end) {
      this.timerOptions.end = new Date(now.getTime() + Number(this.formData.formExpirationDelta));
    }
  }
}
</script>
