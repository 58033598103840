<template>
  <div style="flex: 1;">
    <form ref="dataForm">
      <Modal class="pre-formatted" v-if="showCompleteModal && modalCompleteText"
             v-bind:body="modalCompleteText ? modalCompleteText : ''" v-bind:header="modalCompleteHeader"
             @close="showCompleteModalWindow()"></Modal>
      <component v-bind:is="'DirectPaymentDestinationData'" ref="formComponent"
                 v-bind="{formData: formData}"></component>
      <input ref="dataFormSubmit" style="display:none" type="submit">
      <div v-if="error" class="error-label brand-error">
        <span class="c-error-line">{{ $t(error) }}</span>
      </div>
    </form>
    <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
    <div class="panel-footer">
      <div class="panel-footer-submit">
        <ModalDirectPayment v-if="showConfirmModal && modalConfirmText" v-bind:body="modalConfirmText ? modalConfirmText : ''"
                            v-bind:header="modalConfirmHeader" @close="handleCloseModal"></ModalDirectPayment>
        <button class="button-pay button-accent brand-button-accent brand-button-border-radius" type="button" @click="clicked > 0 ? submitForm() : showConfirmModalWindow()">
          <template>
            {{ $t('FORM.CONFIRM') }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {Ref} from 'vue-property-decorator';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import TextInputFormField from '@/components/form/common/form/fields/TextInputFormField.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import DirectPaymentDestinationData from '@/components/form/pay/steps/forms/direct/DirectPaymentDestinationData.vue';
import Modal from '@/components/common/Modal.vue';
import ModalDirectPayment from '@/components/common/ModalDirectPayment.vue';

@Component({
  components: {
    ModalDirectPayment,
    Modal,
    FormField, FormRow, TextInputFormField, FormContent, FormCol,
    FormTimer, DirectPaymentDestinationData
  }
})
export default class DirectPaymentDestinationInfo extends PayStepBase {
  @Ref('dataForm') readonly dataForm!: HTMLFormElement;
  @Ref('dataFormSubmit') readonly dataFormSubmit!: HTMLElement;
  @Ref('formComponent') readonly formComponent!: IForm;
  isValid = false;
  clicked = 0;
  showConfirmModal = false;
  modalConfirmText = String(this.$t('FORM.CONFIRM_PAYMENT_INFO'));
  modalConfirmHeader = String(this.$t('FORM.CONFIRM_PAYMENT_INFO_TITLE'));
  showCompleteModal = true;
  modalCompleteText = String(this.$t('FORM.COMPLETE_DP_PAYMENT_INFO'));
  modalCompleteHeader = String(this.$t('FORM.COMPLETE_DP_PAYMENT_INFO_TITLE'));


  mounted(): void {
    this.startExpirationTimer();
  }

  submitForm() {
    this.dataFormSubmit.click();
  }

  showConfirmModalWindow() {
    this.clicked++;
    this.showConfirmModal = true;
  }
  handleCloseModal() { this.showConfirmModal = false; }

  showCompleteModalWindow() {
    this.showCompleteModal = false;
  }

  // todo: получать с бека
  protected startExpirationTimer() {
    const now = new Date();
    if (!this.timerOptions.end) {
      this.timerOptions.end = new Date(now.getTime() + Number(this.formData.methodDestinationInfo.requisitesExpiration));
    }
  }
}
</script>

<style scoped lang="scss">
</style>
