var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-phone-number-input", attrs: { id: _vm.id } },
    [
      !_vm.noCountrySelector
        ? _c(
            "div",
            { staticClass: "select-country-container" },
            [
              _c(
                "CountrySelector",
                {
                  ref: "CountrySelector",
                  staticClass: "input-country-selector",
                  attrs: {
                    id: _vm.uniqueId + "_country_selector",
                    "countries-height": _vm.countriesHeight,
                    disabled: _vm.disabled,
                    error: _vm.shouldChooseCountry,
                    hint: _vm.shouldChooseCountry
                      ? _vm.t.countrySelectorError
                      : null,
                    "ignored-countries": _vm.ignoredCountries,
                    items: _vm.codesCountries,
                    label: _vm.t.countrySelectorLabel,
                    "no-flags": _vm.noFlags,
                    "only-countries": _vm.onlyCountries,
                    "preferred-countries": _vm.preferredCountries,
                    "show-code-on-list": _vm.showCodeOnList,
                    valid: _vm.isValid && !_vm.noValidatorState,
                  },
                  model: {
                    value: _vm.countryCode,
                    callback: function ($$v) {
                      _vm.countryCode = $$v
                    },
                    expression: "countryCode",
                  },
                },
                [_vm._t("arrow", null, { slot: "arrow" })],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "InputTel",
            _vm._b(
              {
                ref: "PhoneNumberInput",
                attrs: {
                  id: _vm.uniqueId + "_phone_number",
                  disabled: _vm.disabled,
                  error: _vm.error,
                  hint: _vm.hintValue,
                  label: _vm.t.phoneNumberLabel,
                  "no-country-selector": _vm.noCountrySelector,
                  required: _vm.required,
                  valid: _vm.isValid && !_vm.noValidatorState,
                },
                on: {
                  blur: function ($event) {
                    return _vm.$emit("phone-number-blur")
                  },
                  focus: function ($event) {
                    return _vm.$emit("phone-number-focused")
                  },
                  keydown: function (e) {
                    _vm.lastKeyPressed = e.keyCode
                  },
                },
                model: {
                  value: _vm.phoneNumber,
                  callback: function ($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber",
                },
              },
              "InputTel",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }