var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-root" },
    [
      _vm.loading ? _c("spinner") : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            [
              _c("FormHeader", {
                attrs: {
                  amount: _vm.formData.amount,
                  "back-url": _vm.formData.backUrl,
                  brand: _vm.brandInfo,
                  currency: _vm.formData.currency,
                  "currency-scale": _vm.formData.currencyScale,
                  description: _vm.formData.description,
                  method: _vm.formData.paymentMethod,
                  orderId: _vm.formData.orderId,
                  "return-url": _vm.formData.returnUrl,
                },
              }),
              [
                _c(
                  _vm.currentFormComponent,
                  _vm._b(
                    {
                      tag: "component",
                      on: {
                        expired: _vm.expired,
                        "form-data-changed": _vm.formDataChanged,
                        "on-submit": _vm.submit,
                      },
                    },
                    "component",
                    { formData: _vm.formData },
                    false
                  )
                ),
              ],
              _c("FormFooter", {
                attrs: {
                  brand: _vm.brandInfo,
                  showCardIcons: _vm.formData.paymentMethod === "CARD",
                },
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }