var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    [
      _c(
        "FormField",
        [
          _c("CardPanInputFormField", {
            ref: "cardPanField",
            attrs: {
              value: _vm.formFields.destination,
              inputName: "destination",
            },
            on: {
              changed: function ($event) {
                return _vm.changePan($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "FormField",
        [
          _c("ReceiverNameInputFormField", {
            ref: "cardHolderField",
            attrs: { value: _vm.formFields.receiverName },
            on: {
              changed: function ($event) {
                return _vm.changeCardHolder($event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }