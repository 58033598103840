var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "method-type-select_label" }, [
        _vm._v(_vm._s(_vm.$t("FORM.SELECT_METHOD_TYPE"))),
      ]),
      _vm._l(_vm.formData.serviceMethodTypes, function (methodType) {
        return _c("P2PMethodTypeButton", {
          key: methodType,
          attrs: { type: methodType },
          on: { "method-type-selected": _vm.selectMethodType },
        })
      }),
      _c("FormTimer", {
        attrs: { options: _vm.timerOptions },
        on: { expired: _vm.expired },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }