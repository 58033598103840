var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-root" },
    [
      _vm.loading ? _c("spinner") : _vm._e(),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "main-info brand-iframe" }, [
              _c(
                "div",
                {
                  staticClass:
                    "main-info-body no-select brand-secondary brand-header-border-radius-bb brand-header-border-radius-bt",
                },
                [
                  _c("div", { staticClass: "main-info-body-label" }, [
                    _c("div", { staticClass: "payout-label" }, [
                      _c("span", { staticClass: "order-text" }, [
                        _vm._v(_vm._s(_vm.$t("QR_FORM.LABEL")) + ":"),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm.redirectUrl
              ? _c(
                  "div",
                  { staticClass: "panel-body" },
                  [
                    _c("qrcode-vue", {
                      staticClass: "qrcode",
                      attrs: { size: 400, value: this.redirectUrl, level: "L" },
                    }),
                    _c("div", { staticClass: "panel-footer" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "panel-footer-submit no-select" },
                          [
                            _vm.isButton
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button-pay  brand-button brand-button-border-radius",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.followRedirect()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("QR_FORM.FOLLOW_REDIRECT")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isButton
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button-pay brand-button brand-button-border-radius",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("QR_FORM.COPY_TO_CLIPBOARD")
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "clearfix" }),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }