var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
    },
    [
      _c("CountrySelector", {
        ref: "CountrySelector",
        staticClass: "input-country-selector",
        attrs: {
          id: "countryCode",
          "countries-height": 30,
          disabled: _vm.disabled,
          error: _vm.invalid,
          "ignored-countries": _vm.ignoredCountries,
          items: _vm.codesCountries,
          placeholder: _vm.$t("FORM.DATA.COUNTRY_CODE"),
          "no-flags": _vm.noFlags,
          "only-countries": _vm.onlyCountries,
          "preferred-countries": _vm.preferredCountries,
          valid: !_vm.invalid,
          input: _vm.changedCountry,
          "show-country-name": true,
          "show-code-on-list": false,
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _c("input", {
        attrs: { name: _vm.inputName || "countryCode", type: "hidden" },
        domProps: { value: _vm.inputValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }