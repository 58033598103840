<template>
  <div>
    <LocalisationDebugToggle v-if="debugMode"></LocalisationDebugToggle>
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {LocalizationDebugSrv} from '@/services/LocalizationDebugSrv';
import LocalisationDebugToggle from '@/components/common/LocalisationDebugToggle.vue';

@Component({components: {LocalisationDebugToggle}})
export default class App extends Vue {

  get debugMode(): boolean {
    return LocalizationDebugSrv.debugMode;
  }

  mounted() {
    document.body.classList.add('brand-primary');
  }

  destroyed() {
    document.body.classList.remove('brand-primary');
  }
}
</script>
<style lang="scss">
@import './assets/styles/main.scss';

:host {
  width: inherit;
  display: block;
  //box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.40);
  border-radius: 10px;
  padding: 20px;
}
</style>
