var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _vm.termsOfService
      ? _c("div", {
          staticClass: "footer-text no-select brand-terms-of-service",
          domProps: { innerHTML: _vm._s(_vm.termsOfService) },
        })
      : _vm._e(),
    _vm.showCardIcons
      ? _c(
          "div",
          { staticClass: "footer-icons brand-card-icons" },
          [_c("CardIcons")],
          1
        )
      : _vm._e(),
    _vm.projectLogo
      ? _c(
          "div",
          { staticClass: "footer-project no-select brand-project-logo" },
          [
            _c("img", {
              staticClass: "footer-project-img",
              attrs: { src: _vm.projectLogo, alt: "" },
            }),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "footer-poweredby no-select brand-powered-by" },
      [_c("PoweredByPaidora")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }