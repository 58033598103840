var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "parent",
      staticClass: "country-selector",
      class: [
        {
          "is-focused": _vm.isFocus,
          "has-value": _vm.value,
          "has-hint": _vm.hint,
          "has-error": _vm.error,
          "is-disabled": _vm.disabled,
          "no-flags": _vm.noFlags,
          "has-list-open": _vm.hasListOpen,
          "is-valid": _vm.valid,
        },
        _vm.size,
      ],
      on: {
        mouseenter: function ($event) {
          return _vm.updateHoverState(true)
        },
        mouseleave: function ($event) {
          return _vm.updateHoverState(false)
        },
        "!blur": function ($event) {
          return _vm.handleBlur.apply(null, arguments)
        },
      },
    },
    [
      _vm.value && !_vm.noFlags
        ? _c(
            "div",
            {
              staticClass: "country-selector__country-flag",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleList.apply(null, arguments)
                },
              },
            },
            [
              _c("div", {
                class: "iti-flag-small iti-flag " + _vm.value.toLowerCase(),
              }),
            ]
          )
        : _vm._e(),
      _c("input", {
        ref: "CountrySelector",
        staticClass: "country-selector__input brand-secondary",
        attrs: {
          id: _vm.id,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          readonly: "",
        },
        domProps: { value: _vm.callingCode },
        on: {
          focus: function ($event) {
            _vm.isFocus = true
          },
          keydown: _vm.keyboardNav,
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "country-selector__toggle",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleList.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("arrow", function () {
            return [
              _c(
                "svg",
                {
                  staticClass: "country-selector__toggle__arrow",
                  attrs: {
                    height: "24",
                    mlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24",
                    width: "24",
                  },
                },
                [
                  _c("path", {
                    staticClass: "arrow",
                    attrs: {
                      d: "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z",
                    },
                  }),
                  _c("path", { attrs: { d: "M0 0h24v24H0V0z", fill: "none" } }),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("Transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasListOpen,
                expression: "hasListOpen",
              },
            ],
            ref: "countriesList",
            staticClass:
              "country-selector__list brand-primary brand-primary-border",
            class: { "has-calling-code": _vm.showCodeOnList },
            style: [_vm.listHeight],
          },
          [
            _c("RecycleScroller", {
              attrs: {
                "item-size": 1,
                items: _vm.countriesSorted,
                "key-field": "iso2",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "button",
                        {
                          key: "item-" + item.code,
                          staticClass:
                            "align-center country-selector__list__item brand-primary brand-primary-hover",
                          class: [
                            { "brand-secondary": _vm.value === item.iso2 },
                            { selected: _vm.value === item.iso2 },
                            {
                              "keyboard-selected":
                                _vm.value !== item.iso2 &&
                                _vm.tmpValue === item.iso2,
                            },
                          ],
                          style: [_vm.itemHeight],
                          attrs: { tabindex: "-1", type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.updateValue(item.iso2)
                            },
                          },
                        },
                        [
                          !_vm.noFlags
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "country-selector__list__item__flag-container",
                                },
                                [
                                  _c("div", {
                                    class:
                                      "iti-flag-small iti-flag " +
                                      item.iso2.toLowerCase(),
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "dots-text" },
                            [
                              _c("b", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _vm.showCodeOnList
                                ? [_vm._v("+" + _vm._s(item.dialCode))]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }