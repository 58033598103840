<template>
  <FormContent>
    <div v-for="field in formData.additionalParams.fields" :key="field.key">
      <div
        v-if="field.type === 'FIRST_NAME' || field.type === 'LAST_NAME' || field.type === 'TEXT' || field.type === 'INFO'">
        <FormRow>
          <FormCol>
            <FormField>
                <TextInputFormField
                  ref="field.key"
                  :inputName="field.key"
                  :label="field.key"
                  :regexp="field.regex"
                  v-model="formFields[field.key]"
                  @changed="fieldChanged(field.key, formFields[field.key])"
                ></TextInputFormField>
            </FormField>
          </FormCol>
        </FormRow>
      </div>
        <div v-if="field.type === 'PHONE'">
          <FormRow>
            <FormCol>
              <FormField>
                <MobilePhoneInputFormField
                  ref="field.key"
                  :inputName="field.key"
                  v-model="formFields[field.key]"
                  @changed="fieldChanged(field.key, formFields[field.key])"
                ></MobilePhoneInputFormField>
              </FormField>
            </FormCol>
          </FormRow>
        </div>
        <div v-if="field.type === 'EMAIL'">
          <FormRow>
            <FormCol>
              <FormField>
                <TextInputFormField
                  ref="field.key"
                  :inputName="field.key"
                  :label="field.key"
                  :regexp="field.regex"
                  :min="2"
                  :max="255"
                  v-model="formFields[field.key]"
                  @changed="fieldChanged(field.key, formFields[field.key])"
                ></TextInputFormField>
              </FormField>
            </FormCol>
          </FormRow>
        </div>
        <div v-if="field.type === 'CARD'">
          <FormRow>
            <FormCol>
              <FormField>
                <CardInputFormField
                  ref="field.key"
                  @focusin="sendEventToFocusIn($event)"
                  @focusout="sendEventToFocusOut($event)"
                  v-model="formFields[field.key]"
                  @changed="fieldChanged(field.key, formFields[field.key])"
                ></CardInputFormField>
              </FormField>
            </FormCol>
          </FormRow>
        </div>
        <div v-if="field.type === 'SECRET'">
          <FormRow>
            <FormCol>
              <FormField>
                <SecretTextInputFormField
                  ref="field.key"
                  :inputName=field.key
                  v-model="formFields[field.key]"
                  @changed="fieldChanged(field.key, formFields[field.key])"
                ></SecretTextInputFormField>
              </FormField>
            </FormCol>
          </FormRow>
        </div>
      <div v-if="field.type === 'NUMBER' || field.type === 'AMOUNT'">
        <FormRow>
          <FormCol>
            <FormField>
              <NumericInputFormField
                ref="field.key"
                :inputName="field.key"
                :label="field.key"
                :regexp="field.regex"
                :min="field.minAmount"
                :max="field.maxAmount"
                :required="field.required"
                v-model="formFields[field.key]"
                @changed="fieldChanged(field.key, formFields[field.key])"
              ></NumericInputFormField>
            </FormField>
          </FormCol>
        </FormRow>
      </div>
      </div>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import TextInputFormField from '@/components/form/common/form/fields/TextInputFormField.vue';
import MobilePhoneInputFormField from '@/components/form/common/form/fields/MobilePhoneInputFormField.vue';
import CardInputFormField from "@/components/form/common/form/fields/CardPanInputFormField.vue";
import SecretTextInputFormField from "@/components/form/common/form/fields/SecretTextInputFormField.vue";
import NumericInputFormField from "@/components/form/common/form/fields/NumericInputFormField.vue";

@Component(
  {
    components: {
      NumericInputFormField,
      SecretTextInputFormField,
      CardInputFormField,
      TextInputFormField,
      MobilePhoneInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })
export default class AdditionalStepInputForm extends DataFormBase implements IForm {

  constructor() {
    super();
    this.formFields = {};
  }

  mounted() {
    // Initialize formFields with keys from additionalFields
    this.formData.additionalParams.fields.forEach((field: any) => {
      this.$set(this.formFields, field.key, field.value || '');
    });
  }

  fieldChanged(key: string, value: string) {
    this.formFields[key] = value;

  }
}
</script>

<style lang="scss" scoped>
</style>
