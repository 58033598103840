var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brand && _vm.brand.logo && _vm.brand.companyUrl
    ? _c("div", { staticClass: "logo-panel" }, [
        _c("a", { attrs: { href: _vm.brand.companyUrl, target: "_blank" } }, [
          _c("img", {
            staticClass: "c-logo",
            attrs: { src: _vm.brand.logo, alt: "" },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }