var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
      on: { click: _vm.focusOtpField },
    },
    [
      _c("input", {
        directives: [{ name: "number-only", rawName: "v-number-only" }],
        ref: "otpInput",
        staticClass: "card-input__input brand-secondary brand-primary-focus",
        attrs: {
          maxlength: _vm.minLength || 6,
          minlength: _vm.maxLength || 6,
          name: _vm.inputName || "pan",
          "data-card-field": "",
          inputmode: "numeric",
          placeholder: "Code",
          required: "",
          type: "password",
          autocomplete: "off",
        },
        domProps: { value: _vm.inputValue },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changeOtp($event.target.value)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }