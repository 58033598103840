var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    [
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "firstName",
                    attrs: {
                      inputName: "firstName",
                      label: "FORM.DATA.FIRST_NAME",
                      regexp: /^[а-яА-Яa-zA-Z\s.\-']+$/,
                      value: _vm.formFields.firstName,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "lastName",
                    attrs: {
                      inputName: "lastName",
                      label: "FORM.DATA.LAST_NAME",
                      regexp: /^[а-яА-Яa-zA-Z\s.\-']+$/,
                      value: _vm.formFields.lastName,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("CountryCodeInputFormField", {
                    ref: "countryCode",
                    attrs: {
                      value: _vm.formFields.countryCode,
                      defaultCountryCode: _vm.formData.countryIsoCode,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "address",
                    attrs: {
                      inputName: "address",
                      label: "FORM.DATA.ADDRESS",
                      value: _vm.formFields.address,
                      min: 5,
                      max: 255,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "city",
                    attrs: {
                      inputName: "city",
                      label: "FORM.DATA.CITY",
                      value: _vm.formFields.city,
                      min: 2,
                      max: 255,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "zip",
                    attrs: {
                      inputName: "zip",
                      label: "FORM.DATA.ZIP_CODE",
                      value: _vm.formFields.zip,
                      regexp: /^[а-яА-Яa-zA-Z0-9\s.\-']+$/,
                      min: 2,
                      max: 255,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("MobilePhoneInputFormField", {
                    ref: "phone",
                    attrs: {
                      countryIsoCode: _vm.formData.countryIsoCode,
                      inputName: "phone",
                      value: _vm.formFields.phone,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "email",
                    attrs: {
                      inputName: "email",
                      label: "FORM.DATA.EMAIL",
                      value: _vm.formFields.email,
                      regexp: _vm.emailRegexp,
                      min: 2,
                      max: 255,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }