var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": _vm.transition.enter,
        "leave-active-class": _vm.transition.leave,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive",
            },
          ],
          staticClass: "v-toast__item brand-toast",
          class: [
            "v-toast__item--" + _vm.type,
            "brand-toast--" + _vm.type,
            "v-toast__item--" + _vm.position,
          ],
          attrs: { role: "alert" },
          on: {
            click: _vm.whenClicked,
            mouseleave: function ($event) {
              return _vm.toggleTimer(false)
            },
            mouseover: function ($event) {
              return _vm.toggleTimer(true)
            },
          },
        },
        [
          _c("div", { staticClass: "v-toast__icon" }),
          _c("p", {
            staticClass: "v-toast__text",
            domProps: { innerHTML: _vm._s(_vm.message) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }