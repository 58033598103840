var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    [
      _c(
        "FormRow",
        [
          _c(
            "FormCol",
            [
              _c(
                "FormField",
                [
                  _c("TextInputFormField", {
                    ref: "otp",
                    attrs: {
                      inputName: "otp",
                      label: "FORM.DATA.OTP",
                      value: _vm.formFields.otp,
                      regexp: /^[0-9']+$/,
                      min: 4,
                      max: 12,
                    },
                    on: {
                      changed: function ($event) {
                        return _vm.fieldChanged()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }