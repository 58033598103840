var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    [
      _c("label", [
        _vm.formData.methodDestinationInfo.type === "CARD"
          ? _c("b", [_vm._v(_vm._s(_vm.$t("FORM.P2P_DESCRIPTION_CARD")))])
          : _vm.formData.methodDestinationInfo.type === "BANK_ACCOUNT"
          ? _c("b", [_vm._v(_vm._s(_vm.$t("FORM.P2P_DESCRIPTION_BANK")))])
          : _vm.formData.methodDestinationInfo.type === "OTHER"
          ? _c("b", [_vm._v(_vm._s(_vm.$t("FORM.P2P_DESCRIPTION_OTHER")))])
          : _vm.formData.methodDestinationInfo.type === "PHONE"
          ? _c("b", [_vm._v(_vm._s(_vm.$t("FORM.P2P_DESCRIPTION_PHONE")))])
          : _c("b", [_vm._v(_vm._s(_vm.$t("FORM.P2P_DESCRIPTION_COMMON")))]),
      ]),
      _vm.formData.methodDestinationInfo.description
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            class: { "brand-error-container": _vm.invalid },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { readonly: "" },
                              domProps: {
                                value:
                                  _vm.formData.methodDestinationInfo
                                    .description,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(
                                      _vm.formData.methodDestinationInfo
                                        .description
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formData.methodDestinationInfo.destination &&
      _vm.formData.methodDestinationInfo.type != "CARD"
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            class: { "brand-error-container": _vm.invalid },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { readonly: "" },
                              domProps: {
                                value:
                                  _vm.formData.methodDestinationInfo
                                    .destination,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(
                                      _vm.formData.methodDestinationInfo
                                        .destination
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formData.methodDestinationInfo.destination &&
      _vm.formData.methodDestinationInfo.type == "CARD"
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            class: { "brand-error-container": _vm.invalid },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { readonly: "" },
                              domProps: {
                                value: _vm.showPan(
                                  _vm.formData.methodDestinationInfo.destination
                                ),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(
                                      _vm.formData.methodDestinationInfo
                                        .destination
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formData.methodDestinationInfo.receiverName
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            class: { "brand-error-container": _vm.invalid },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { readonly: "" },
                              domProps: {
                                value:
                                  _vm.formData.methodDestinationInfo
                                    .receiverName,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(
                                      _vm.formData.methodDestinationInfo
                                        .receiverName
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("FormRow", [_c("FormCol", [_c("FormField")], 1)], 1),
      _vm.formData.methodDestinationInfo.amount
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c("FormCol", [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("FORM.AMOUNT_DESCRIPTION.WARNING_MESSAGE")
                          ) +
                          " "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/svg/exclamation_mark.svg"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            class: { "brand-error-container": _vm.invalid },
                          },
                          [
                            _c("div", {
                              staticClass: "main-info-body",
                              attrs: { readonly: "" },
                              domProps: {
                                innerHTML: _vm._s(_vm.amountFormatted),
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(_vm.amountForCopy)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("label", [
        _vm._v(" " + _vm._s(_vm.$t("FORM.INFO_RETURN_MESSAGE")) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }