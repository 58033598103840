var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    [
      _c("label", [
        _c("b", [_vm._v(_vm._s(_vm.$t("FORM.P2P_DESCRIPTION_COMMON")))]),
      ]),
      _vm._l(_vm.formData.additionalParams.fields, function (field) {
        return _c("div", { key: field.key }, [
          _c(
            "div",
            {
              staticClass:
                "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
              class: { "brand-error-container": _vm.invalid },
            },
            [
              _c("input", {
                staticClass:
                  "card-input__input brand-secondary brand-primary-focus",
                attrs: { readonly: "" },
                domProps: { value: _vm.additionalField.value },
              }),
              _c(
                "div",
                {
                  staticClass: "c-info-icon",
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.additionalField.value)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/svg/copy.svg") },
                  }),
                ]
              ),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }