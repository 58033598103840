<template>
  <div style="flex: 1;">
    <label>
      <b>{{ $t(localFormData.additionalParams.header) }}</b>
    </label>
    <form ref="dataForm">
      <div v-if="localFormData.additionalParams.stepType === 'SELECTOR'">
        <AdditionalStepSelectorForm
          v-bind:additionalField="selector" v-bind:key="selector.key"
          v-for="selector in localFormData.additionalParams.fields"
          @selector-selected="submitSelectorData(selector)"></AdditionalStepSelectorForm>
        <input ref="dataFormSubmit" style="display:none" type="submit">
        <div v-if="error" class="error-label brand-error">
          <span class="c-error-line">{{ $t(error) }}</span>
        </div>
      </div>
      <form ref="dataInputForm" @submit.prevent="submitPaymentData()">
        <div v-if="localFormData.additionalParams.stepType === 'FORM_INPUT'">
          <component v-bind:is="'AdditionalStepInputForm'" ref="formComponent"
                     v-bind="{formData: localFormData}" @valid-form="validForm"></component>
          <input ref="dataFormSubmit" style="display:none" type="submit">
          <div v-if="error" class="error-label brand-error">
            <span class="c-error-line">{{ $t(error) }}</span>
          </div>
        </div>
      </form>
      <div v-if="localFormData.additionalParams.stepType === 'SHOW_DESTINATION'">
        <component v-bind:is="'AdditionalStepShowInfoForm'" ref="formComponent"
                   v-bind="{formData: localFormData}"></component>
        <input ref="dataFormSubmit" style="display:none" type="submit">
        <div v-if="error" class="error-label brand-error">
          <span class="c-error-line">{{ $t(error) }}</span>
        </div>
      </div>
    </form>
    <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
    <div class="panel-footer">
      <div class="panel-footer-submit">
        <div v-if="localFormData.additionalParams.submitButtonText">
          <button class="button-pay button-accent brand-button-accent  brand-button-border-radius"
                  type="button"
                  v-on:click="submitForm()">
            <template>
              {{ $t(localFormData.additionalParams.submitButtonText) }}
            </template>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {Ref} from 'vue-property-decorator';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import AdditionalStepSelectorForm from "@/components/form/pay/steps/forms/additional/AdditionalStepSelectorForm.vue";
import AdditionalStepShowInfoForm from "@/components/form/pay/steps/forms/additional/AdditionalStepShowInfoForm.vue";
import AdditionalStepInputForm from "@/components/form/pay/steps/forms/additional/AdditionalStepInputForm.vue";
import FormSubmitRequest = com.paidora.billing.app.form.requests.FormSubmitRequest;
import PaymentFormInfo = com.paidora.billing.app.form.models.PaymentFormInfo;
import AdditionalFieldBase = com.paidora.billing.types.models.core.additional.info.steps.AdditionalFieldBase;

@Component({
  components: {
    AdditionalStepInputForm,
    AdditionalStepShowInfoForm,
    FormTimer,
    AdditionalStepSelectorForm
  }
})
export default class AdditionalParamStepForm extends PayStepBase {
  @Ref('dataForm') readonly dataForm!: HTMLFormElement;
  @Ref('dataInputForm') readonly dataInputForm!: HTMLFormElement;
  @Ref('dataFormSubmit') readonly dataFormSubmit!: HTMLElement;
  @Ref('formComponent') readonly formComponent!: IForm;
  isValid = false;
  submitting = false;
  currentFormComponent = '';
  request?: FormSubmitRequest;

  // Create a local copy of formData
  localFormData = {...this.formData};

  mounted(): void {
    this.startExpirationTimer();
  }

  nextAdditionalStep(paymentData: PaymentFormInfo) {
    switch (paymentData.additionalParams.stepType) {
      case 'SELECTOR':
        this.currentFormComponent = 'AdditionalStepSelectorForm';
        break;
      case 'FORM_INPUT':
        this.currentFormComponent = 'AdditionalStepInputForm';
        break;
      case 'SHOW_DESTINATION':
        this.currentFormComponent = 'AdditionalStepShowInfoForm';
        break;
    }
  }

  submitSelectorData(field: AdditionalFieldBase) {
    if (this.submitting) {
      return;
    }

    const selector: { [key: string]: string } = {};
    selector[field.key] = field.value;

    let request: FormSubmitRequest = {
      values: <any>null,
      type: 'ADDITIONAL_INPUT',
      browserInfo: <any>null,
      method: this.formData.paymentMethod,
      methodType: this.formData.serviceMethodType,
      additionalParams: selector
    };
    this.request = request;
    if (field.childStep) {
      this.localFormData.additionalParams = field.childStep;
      this.nextAdditionalStep(this.data as PaymentFormInfo);
    }
    this.$emit('on-submit', this.formData.token, request);
  }

  submitPaymentData() {
    if (this.submitting) {
      return;
    }

    this.formComponent.validate();
    if (!this.isValid) {
      this.$toast.error(this.$t('ERROR.FORM_FIELDS') as string);
      return;
    }

    const elements = Array.from(this.dataInputForm) as HTMLInputElement[];
    let values = elements.reduce((acc: { [key: string]: string }, el: HTMLInputElement) => {
      if (el.name) {
        acc[el.name] = el.value;
      }
      return acc;
    }, {});

    let request: FormSubmitRequest = {
      values: <any>null,
      type: 'ADDITIONAL_INPUT',
      browserInfo: <any>null,
      method: this.formData.paymentMethod,
      methodType: this.formData.serviceMethodType,
      additionalParams: values
    };
    if (this.request) {
      this.request.additionalParams = this.request.additionalParams || [];
      this.request.additionalParams = Object.assign(request.additionalParams, this.request.additionalParams);
    } else {
      this.request = request;
    }
    this.$emit('on-submit', this.formData.token, this.request);
  }

  submitForm() {
    this.dataFormSubmit.click();
  }

  protected validForm(valid: boolean) {
    this.isValid = valid;
  }

  protected setError(error?: string | undefined) {
    this.error = error || 'ERROR.COMMON';
  }
}
</script>

<style scoped lang="scss">
.customer-data__label {
  display: flex;
  font-size: 14px;
  margin: 24px 0 0;
  justify-content: center;
}
</style>
