<template>
  <div class="payment-method-type-btn brand-secondary brand-secondary-hw brand-button-border-radius" @click="methodTypeSelected">
<!--    <img  class="payment-method-type-btn__logo" :src="require(`@/assets/methods/${methodLogo}`"/>-->
    <div class="payment-method-type-btn__label" v-if="$te('METHOD_TYPE.' + type.toUpperCase())">{{ $t('METHOD_TYPE.' + type.toUpperCase()) }}</div>
    <div class="payment-method-type-btn__label_capitalize" v-else>{{  type }}</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';


@Component({})
export default class P2PMethodTypeButton extends Vue {
  @Prop()
  type!: string;
  methodTypeLogos: { [method: string]: { alias: string, extension: string } } = {
    'bkash': {
      alias: 'bkash',
      extension: 'svg'
    },
    'card': {
      alias: 'card',
      extension: 'svg'
    },
    'nagad': {
      alias: 'nagad',
      extension: 'svg'
    },
    'pk_bank': {
      alias: 'pk_bank',
      extension: 'svg'
    },
    'pk_easypaisa': {
      alias: 'pk_easypaisa',
      extension: 'svg'
    },
    'pk_jazzcash': {
      alias: 'pk_jazzcash',
      extension: 'svg'
    },
    'rocket': {
      alias: 'rocket',
      extension: 'svg'
    },
    'sberpay': {
      alias: 'sberpay',
      extension: 'svg'
    },
    'sbp': {
      alias: 'sbp',
      extension: 'png'
    },
    'upay': {
      alias: 'upay',
      extension: 'svg'
    },
    'other': {
      alias: 'other',
      extension: 'svg'
    }
  };

  @Emit('method-type-selected')
  methodTypeSelected() {
    if (this.methodTypeLogos[this.type]) {
      return this.methodTypeLogos[this.type].alias;
    } else {
      return 'other';
    }
  }

  get methodLogo() {
      return `${this.type.toLowerCase()}.${this.methodTypeLogos[this.type].extension}`;
  }
}
</script>

<style lang="scss">
.payment-method-type-btn {
  height: 60px;
  font-size: 18px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .payment-method-type-btn__label {

  }

  .payment-method-type-btn__label_capitalize {
    text-transform: capitalize;
  }

  .payment-method-type-btn__logo {
    display: block;
    max-width: 50%;
    max-height: 25px;
    width: auto;
    height: 100%;
  }
}
</style>
