<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}"
       @click="$refs.textField.focus()">
    <input
      ref="textField"
      :placeholder="$t(label)"
      :value="inputValue"
      class="card-input__input brand-secondary brand-primary-focus"
      data-card-field
      :maxlength="max"
      :minlength="min"
      :name="inputName"
      required
      type="password"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changeInputValue($event.target.value)"
    />
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';
import {Prop} from 'vue-property-decorator';

@Component
export default class SecretTextInputFormField extends InputFormFieldBase<string> {
  @Prop()
  label!: string;

  @Prop()
  regexp?: string;

  @Prop({default: 1})
  min!: number;

  @Prop({default: 255})
  max!: number;

  constructor() {
    super();
    this.inputValue = '';
  }

  focusField() {
    (this.$refs.textField as any).focus();
  }

  changeInputValue(value: string) {
    this.inputValue = value.trim();
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: false
    });
  }

  isInvalid(): boolean {
    return !this.inputValue ||
      this.inputValue.length > this.max ||
      this.inputValue.length < this.min ||
      (!!this.regexp && !this.inputValue.match(this.regexp));
  }

  getErrorMessage(): string {
    return 'ERROR.TEXT_INPUT';
  }
}
</script>

<style scoped>

</style>
