var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { flex: "1" } },
    [
      _c("label", [
        _c("b", [
          _vm._v(_vm._s(_vm.$t(_vm.localFormData.additionalParams.header))),
        ]),
      ]),
      _c("form", { ref: "dataForm" }, [
        _vm.localFormData.additionalParams.stepType === "SELECTOR"
          ? _c(
              "div",
              [
                _vm._l(
                  _vm.localFormData.additionalParams.fields,
                  function (selector) {
                    return _c("AdditionalStepSelectorForm", {
                      key: selector.key,
                      attrs: { additionalField: selector },
                      on: {
                        "selector-selected": function ($event) {
                          return _vm.submitSelectorData(selector)
                        },
                      },
                    })
                  }
                ),
                _c("input", {
                  ref: "dataFormSubmit",
                  staticStyle: { display: "none" },
                  attrs: { type: "submit" },
                }),
                _vm.error
                  ? _c("div", { staticClass: "error-label brand-error" }, [
                      _c("span", { staticClass: "c-error-line" }, [
                        _vm._v(_vm._s(_vm.$t(_vm.error))),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _c(
          "form",
          {
            ref: "dataInputForm",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitPaymentData()
              },
            },
          },
          [
            _vm.localFormData.additionalParams.stepType === "FORM_INPUT"
              ? _c(
                  "div",
                  [
                    _c(
                      "AdditionalStepInputForm",
                      _vm._b(
                        {
                          ref: "formComponent",
                          tag: "component",
                          on: { "valid-form": _vm.validForm },
                        },
                        "component",
                        { formData: _vm.localFormData },
                        false
                      )
                    ),
                    _c("input", {
                      ref: "dataFormSubmit",
                      staticStyle: { display: "none" },
                      attrs: { type: "submit" },
                    }),
                    _vm.error
                      ? _c("div", { staticClass: "error-label brand-error" }, [
                          _c("span", { staticClass: "c-error-line" }, [
                            _vm._v(_vm._s(_vm.$t(_vm.error))),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm.localFormData.additionalParams.stepType === "SHOW_DESTINATION"
          ? _c(
              "div",
              [
                _c(
                  "AdditionalStepShowInfoForm",
                  _vm._b(
                    { ref: "formComponent", tag: "component" },
                    "component",
                    { formData: _vm.localFormData },
                    false
                  )
                ),
                _c("input", {
                  ref: "dataFormSubmit",
                  staticStyle: { display: "none" },
                  attrs: { type: "submit" },
                }),
                _vm.error
                  ? _c("div", { staticClass: "error-label brand-error" }, [
                      _c("span", { staticClass: "c-error-line" }, [
                        _vm._v(_vm._s(_vm.$t(_vm.error))),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("FormTimer", {
        attrs: { options: _vm.timerOptions },
        on: { expired: _vm.expired },
      }),
      _c("div", { staticClass: "panel-footer" }, [
        _c("div", { staticClass: "panel-footer-submit" }, [
          _vm.localFormData.additionalParams.submitButtonText
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass:
                      "button-pay button-accent brand-button-accent  brand-button-border-radius",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm()
                      },
                    },
                  },
                  [
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.localFormData.additionalParams
                                .submitButtonText
                            )
                          ) +
                          " "
                      ),
                    ],
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }