var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "main-info brand-header brand-iframe brand-header-border-radius",
    },
    [
      _c(
        "div",
        { staticClass: "main-info-header-merchant brand-secondary-bb" },
        [
          !_vm.isIFrame && this.backUrl
            ? _c(
                "div",
                { staticClass: "main-info-header-merchant-back-button" },
                [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.navBack()
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            fill: "none",
                            height: "16",
                            viewBox: "0 0 9 16",
                            width: "9",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            staticClass: "brand-header",
                            attrs: {
                              "clip-rule": "evenodd",
                              d: "M8.62342 0.390524C9.12553 0.911223 9.12553 1.75544 8.62342 2.27614L3.10399 8L8.62342 13.7239C9.12553 14.2446 9.12553 15.0888 8.62342 15.6095C8.12132 16.1302 7.30725 16.1302 6.80515 15.6095L0.376577 8.94281C-0.125526 8.42211 -0.125526 7.57789 0.376577 7.05719L6.80515 0.390524C7.30725 -0.130175 8.12132 -0.130175 8.62342 0.390524Z",
                              "fill-rule": "evenodd",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.brand.merchantLogo
            ? _c("div", { staticClass: "main-info-header-merchant-image" }, [
                _c("img", {
                  staticClass: "main-info-header-merchant-image-img",
                  attrs: { src: _vm.brand.merchantLogo, alt: "" },
                }),
              ])
            : _vm._e(),
          _vm.brand.merchantName
            ? _c("div", { staticClass: "main-info-header-merchant-name" }, [
                _vm._v(" " + _vm._s(_vm.brand.merchantName)),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "main-info-body no-select brand-header-bottom" },
        [
          _c("div", { staticClass: "main-info-body-price-and-details" }, [
            _vm.amount
              ? _c("div", {
                  staticClass: "main-info-body-price-and-details-price",
                  domProps: { innerHTML: _vm._s(_vm.amountFormatted) },
                })
              : _vm._e(),
          ]),
          _vm.description
            ? _c(
                "div",
                {
                  staticClass: "main-info-body-label",
                  staticStyle: { "word-break": "break-all" },
                },
                [_vm._v(" " + _vm._s(_vm.description))]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }