var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timer no-select" },
    [
      _vm.displaySeconds
        ? [
            _vm._v(_vm._s(_vm.$t("FORM.TIMER.TIME_LEFT")) + " "),
            Number(_vm.displayDays) > 0
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.displayDays) +
                      " " +
                      _vm._s(_vm.$t("FORM.TIMER.DAYS")) +
                      " "
                  ),
                ])
              : _vm._e(),
            Number(_vm.displayHours) > 0
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.displayHours) +
                      " " +
                      _vm._s(_vm.$t("FORM.TIMER.HOURS")) +
                      " "
                  ),
                ])
              : _vm._e(),
            Number(_vm.displayMinutes) > 0
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.displayMinutes) +
                      " " +
                      _vm._s(_vm.$t("FORM.TIMER.MINS")) +
                      " "
                  ),
                ])
              : _vm._e(),
            Number(_vm.displaySeconds) > 0
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.displaySeconds) +
                      " " +
                      _vm._s(_vm.$t("FORM.TIMER.SECS"))
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }