var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-input__wrapper brand-input-border-radius",
      class: {
        invalid: _vm.invalid,
        "brand-error": _vm.invalid,
      },
    },
    [
      _c("div", { staticClass: "card-input__error" }, [
        _vm._v(_vm._s(_vm.$t(_vm.error))),
      ]),
      _c(
        "div",
        {
          staticClass: "card-input brand-secondary brand-input-border-radius",
          class: { "brand-error": _vm.invalid },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }