<template>
  <div class="modal-mask" @click.self="submitForm">
    <div class="modal-wrapper no-select">
      <div class="modal-container brand-header-border-radius brand-primary">
        <div class="modal-header" v-if="header">
          <span v-html="header"></span>
        </div>
        <div class="modal-body" v-if="body">
          <span v-html="body"></span>
        </div>
        <div class="modal-footer brand-secondary brand-secondary-bb" @click.stop="submitForm">
          <button class="modal-button">
            {{ $t('FORM.CONFIRM') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Ref} from 'vue-property-decorator';

@Component
export default class ModalDirectPayment extends Vue {
  @Prop()
  body?: string;

  @Prop()
  header?: string;

  submitForm() {
    this.$emit('close');
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/main";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  transition: all 0.3s ease;
  font-family: Montserrat-Medium, Helvetica, Arial, sans-serif;
  font-weight: 800;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 460px) {
    font-size: 14px;
    width: 400px;
    max-height: 440px;
  }

  @media screen and (min-width: 440px) and (max-width: 459px) {
    font-size: 14px;
    width: 400px;
    max-height: 350px;
  }

  @media screen and (min-width: 400px) and (max-width: 439px) {
    font-size: 12px;
    width: 360px;
    max-height: 350px;
  }

  @media screen and (min-width: 360px) and (max-width: 399px) {
    font-size: 10px;
    width: 320px;
    max-height: 350px;
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    font-size: 10px;
    width: 280px;
    max-height: 350px;
  }

  overflow-y: auto;

  .modal-header {
    padding: 20px 30px 0px 20px;
    margin-top: 0;
    font-family: Montserrat-Extra-Bold, Helvetica, Arial, sans-serif;
    font-weight: 800;

    @media screen and (min-width: 460px) {
      font-size: 16px;
    }

    @media screen and (min-width: 440px) and (max-width: 459px) {
      font-size: 16px;
    }

    @media screen and (min-width: 400px) and (max-width: 439px) {
      font-size: 14px;
    }

    @media screen and (min-width: 360px) and (max-width: 399px) {
      font-size: 12px;
    }

    @media screen and (min-width: 320px) and (max-width: 359px) {
      font-size: 12px;
    }
  }

  .modal-body {
    margin: 20px 0;
    height: 80%;
    overflow-y: auto;
    padding: 0px 20px 20px 20px;
  }

  .modal-footer {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: none;
    height: 50px;

    .modal-button {
      text-align: center;
    }
  }
}
</style>
