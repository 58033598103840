var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-root" },
    [
      _vm.showModal && _vm.modalText
        ? _c("Modal", {
            attrs: {
              body: _vm.modalText ? _vm.modalText : "",
              header: _vm.modalHeader,
            },
            on: {
              close: function ($event) {
                return _vm.closeModalWindow()
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "status-label no-select" }, [
        _vm._v(_vm._s(_vm.$t("FORM.COMMON_ERROR"))),
      ]),
      _c("div", { staticClass: "status-bill" }, [
        _c("img", {
          attrs: { src: require("@/assets/svg/error.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "panel-footer" }, [
        _c("div", { staticClass: "panel-footer-submit" }, [
          _vm.modalText
            ? _c(
                "button",
                {
                  staticClass: "button-black brand-button-border-radius",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.showModalWindow()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("FORM.BUTTON.WHAT_HAPPENED")) + " "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c("ErrorFooter", { staticStyle: { "margin-top": "25px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }