<template>
  <a @click="toggleLanguage()" role="button" class="debug-toggle a1">debug: ru/en/off</a>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {LocalizationDebugSrv} from '@/services/LocalizationDebugSrv';

enum LanguageDebugMode {
  RU, EN, OFF
}

@Component({})
export default class LocalisationDebugToggle extends Vue {
  currentMode: LanguageDebugMode = LanguageDebugMode.EN;

  mounted(): void {
    if (LocalizationDebugSrv.showTokens) {
      this.currentMode = LanguageDebugMode.OFF;
    } else {
      switch (this.$root.$i18n.locale) {
        case 'ru':
          this.currentMode = LanguageDebugMode.RU;
          break;
        case 'en':
        default:
          this.currentMode = LanguageDebugMode.EN;
          break;
      }
    }
  }

  toggleLanguage() {
    this.currentMode = (this.currentMode + 1) % 3;
    switch (this.currentMode) {
      case LanguageDebugMode.RU:
        LocalizationDebugSrv.showTokens = false;
        this.$root.$i18n.locale = 'ru';
        break;
      case LanguageDebugMode.EN:
        LocalizationDebugSrv.showTokens = false;
        this.$root.$i18n.locale = 'en';
        break;
      case LanguageDebugMode.OFF:
        LocalizationDebugSrv.showTokens = true;
        this.$root.$i18n.locale = 'debug';
        break;
    }
    console.log('toggleLanguageMode', this.currentMode);
  }
}
</script>


<style scoped lang="scss">
.debug-toggle {
  position: absolute;
  right: 20px;
  z-index: 1000;

  cursor: pointer;
  color: red;
  font-size: 14px;
}

.debug-toggle.a1 {
  top: 64px;
}

.debug-toggle.a2 {
  top: 84px;
}
</style>
