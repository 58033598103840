var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { flex: "1" } },
    [
      _c("div", { staticClass: "customer-data__label" }, [
        _vm._v(_vm._s(_vm.$t("FORM.OTP.LABEL"))),
      ]),
      _c(
        "form",
        {
          ref: "dataForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitPaymentData()
            },
          },
        },
        [
          _c(
            "OtpDataForm",
            _vm._b(
              {
                ref: "formComponent",
                tag: "component",
                on: { "valid-form": _vm.validForm },
              },
              "component",
              { formData: _vm.formData },
              false
            )
          ),
          _c("input", {
            ref: "dataFormSubmit",
            staticStyle: { display: "none" },
            attrs: { type: "submit" },
          }),
          _vm.error
            ? _c("div", { staticClass: "error-label brand-error" }, [
                _c("span", { staticClass: "c-error-line" }, [
                  _vm._v(_vm._s(_vm.$t(_vm.error))),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("FormTimer", {
        attrs: { options: _vm.timerOptions },
        on: { expired: _vm.expired },
      }),
      _c("div", { staticClass: "panel-footer" }, [
        _c("div", { staticClass: "panel-footer-submit" }, [
          _c(
            "button",
            {
              staticClass:
                "button-pay button-accent brand-button-accent  brand-button-border-radius",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [[_vm._v(" " + _vm._s(_vm.$t("FORM.NEXT")) + " ")]],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }