<template>
  <div style="flex: 1;">
    <div class="panel-body">
      <form ref="dataForm" @submit.prevent="submitPaymentData()">
        <div v-if="this.formData.serviceMethodType !='pk_bank' && this.formData.serviceMethodType != 'pk_easypaisa'
         && this.formData.serviceMethodType != 'pk_jazzcash' && this.formData.serviceMethodType || this.formData.paymentMethod == 'MULTIBANCO'">
          <component v-bind:is="currentFormComponent" v-if="currentFormComponent" ref="formComponent"
                     v-bind="{formData: formData}" @valid-form="validForm"></component>
          <component v-bind:is="currentFormComponent" v-if="currentFormComponent" ref="formComponent"
                     v-bind="{formData: formData}" @submit-form="submitForm"></component>
        </div>
        <component v-bind:is="currentFormComponent" v-if="currentFormComponent" ref="formComponent"
                   v-bind="{formData: formData}" @valid-form="validForm"></component>
        <input ref="dataFormSubmit" style="display:none" type="submit">
        <div v-if="error" class="error-label brand-error">
          <span class="c-error-line">{{ $t(error) }}</span>
        </div>
      </form>
      <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
      <div class="panel-footer">
        <div class="panel-footer-submit">
          <button id="pay-button-id" class="button-pay button-accent brand-button-accent  brand-button-border-radius"
                  type="button"
                  v-on:click="submitForm()">
            <svg v-if="!isValid" height="20" viewBox="0 0 17 20" width="17" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.9767 7.82245H14.4736V5.88571C14.4715 2.54286 11.7992 0 8.50106 0C5.20296 0 2.52854 2.54286 2.52854 5.88571V7.82245H2.02537C0.906977 7.82245 0 8.69592 0 9.77143V18.049C0 19.1265 0.906977 20 2.02537 20H14.9746C16.093 20 17 19.1265 17 18.049V9.77347C17 8.69592 16.093 7.82245 14.9767 7.82245ZM5.06765 6.14082C5.06765 4.1551 6.60465 2.54694 8.50106 2.54694C10.3975 2.54694 11.9323 4.15714 11.9323 6.14082V7.88163H5.06765V6.14082ZM9.35307 13.8551V16.0265C9.35307 16.4816 8.97252 16.849 8.50106 16.849C8.0296 16.849 7.64905 16.4796 7.64905 16.0265V13.8551C7.13953 13.5694 6.79493 13.0408 6.79493 12.4306C6.79493 11.5224 7.56025 10.7837 8.50106 10.7837C9.44186 10.7837 10.2051 11.5224 10.2051 12.4306C10.2072 13.0408 9.86258 13.5694 9.35307 13.8551Z"/>
            </svg>
            <template v-if="!loading && !redirecting">
              {{ $t('FORM.PAY') }} <span v-html="amountFormatted"></span>
            </template>
            <template v-else-if="loading || redirecting">
              {{ $t('FORM.PROCESSING') }}
            </template>
          </button>
        </div>
        <div class="panel-footer-submit" v-if="yandexPayShow">
          <YandexPay v-if="!loading && !redirecting && yandexPayShow"
                     v-bind:form-data="this.formData"
                     v-bind:height="this.getButtonHeight()"
                     @onPaymentTokenReceived="onPaymentTokenReceived"></YandexPay>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {Ref} from 'vue-property-decorator';
import CardMethodForm from '@/components/form/pay/steps/forms/CardMethodForm.vue';
import {Log} from '@/utils/log';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import {currencies} from '@/utils/currencies';
import YandexPay from '@/components/form/pay/steps/paybuttons/YandexPay.vue';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import BrowserInfo = com.paidora.billing.types.transaction.BrowserInfo;
import FormSubmitRequest = com.paidora.billing.app.form.requests.FormSubmitRequest;
import PaymentMethodType = com.paidora.billing.types.models.enums.PaymentMethodType;

@Component({
  components: {
    FormTimer,
    YandexPay,
    CardMethodForm,
    GiropayMethodForm: () => import('@/components/form/pay/steps/forms/GiropayMethodForm.vue'),
    SofortMethodForm: () => import('@/components/form/pay/steps/forms/SofortMethodForm.vue'),
    SkrillMethodForm: () => import('@/components/form/pay/steps/forms/SkrillMethodForm.vue'),
    NetellerMethodForm: () => import('@/components/form/pay/steps/forms/NetellerMethodForm.vue'),
    PaysafecardMethodForm: () => import('@/components/form/pay/steps/forms/PaysafecardMethodForm.vue'),
    PaysafecashMethodForm: () => import('@/components/form/pay/steps/forms/PaysafecashMethodForm.vue'),
    RapidTransferMethodForm: () => import('@/components/form/pay/steps/forms/RapidTransferMethodForm.vue'),
    McMethodForm: () => import('@/components/form/pay/steps/forms/McMethodForm.vue'),
    MpayMethodForm: () => import('@/components/form/pay/steps/forms/MpayMethodForm.vue'),
    PkJazzcashMethodForm: () => import('@/components/form/pay/steps/forms/p2p/PkJazzcashMethodForm.vue'),
    PkEasypaisaMethodForm: () => import('@/components/form/pay/steps/forms/p2p/PkEasypaisaMethodForm.vue'),
    PkBankMethodForm: () => import('@/components/form/pay/steps/forms/p2p/PkBankMethodForm.vue'),
    P2PDestinationData: () => import('@/components/form/pay/steps/forms/p2p/P2PDestinationData.vue'),
    MultibancoDestinationData: () => import('@/components/form/pay/steps/forms/multibanco/MultibancoDestinationData.vue'),
    AdditionalParamStepForm: () => import('@/components/form/pay/steps/forms/additional/AdditionalParamStepForm.vue')
  }
})
export default class PaymentData extends PayStepBase {

  @Ref('dataForm') readonly dataForm!: HTMLFormElement;
  @Ref('dataFormSubmit') readonly dataFormSubmit!: HTMLElement;
  @Ref('formComponent') readonly formComponent!: IForm;

  isValid = false;


  loading = false;
  redirecting = false;
  currentFormComponent = '';
  end!: Date;
  yandexPayShow = false;

  get amountFormatted(): string {
    let symbol = currencies[this.formData.currency];
    let formatted = Intl.NumberFormat(this.$i18n.locale, {
      style: 'currency',
      currency: this.formData.currency == 'USDT' ? 'USD' : this.formData.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.amount);

    if (symbol) {
      formatted = formatted.replace(this.formData.currency, symbol);
    }
    if (this.formData.currency == 'USDT') {
      formatted = formatted.replaceAll(/[^\d.,-]/g, '') + ' USDT';
    }
    return formatted;
  }

  mounted(): void {
    switch (this.formData.paymentMethod) {
      case 'MC':
        this.currentFormComponent = 'McMethodForm';
        break;
      case 'CARD':
        this.currentFormComponent = 'CardMethodForm';
        this.yandexPayShow = this.formData.flags?.includes('CARD_YANDEX_PAY');
        break;
      case 'QIWI':
        this.currentFormComponent = 'McMethodForm';
        break;
      case 'GIROPAY':
        this.currentFormComponent = 'GiropayMethodForm';
        break;
      case 'MPAY_S':
        this.currentFormComponent = 'MpayMethodForm';
        break;
      case 'SOFORT':
        this.currentFormComponent = 'SofortMethodForm';
        break;
      case 'SKRILL':
        this.currentFormComponent = 'SkrillMethodForm';
        break;
      case 'NETELLER':
        this.currentFormComponent = 'NetellerMethodForm';
        break;
      case 'PAY_SAFE_CARD':
        this.currentFormComponent = 'PaysafecardMethodForm';
        break;
      case 'PAY_SAFE_CASH':
        this.currentFormComponent = 'PaysafecashMethodForm';
        break;
      case 'RAPID_TRANSFER':
        this.currentFormComponent = 'RapidTransferMethodForm';
        break;
      case 'P2P':
        if (!this.formData.serviceMethodType && this.formData.serviceMethodTypes.length > 1) {
          this.currentFormComponent = 'P2PMethodTypes';
          break;
        } else {
          switch (this.formData.serviceMethodType) {
            case 'pk_easypaisa':
              this.currentFormComponent = 'PkEasypaisaMethodForm';
              break;
            case 'pk_jazzcash':
              this.currentFormComponent = 'PkJazzcashMethodForm';
              break;
            case "pk_bank":
              this.currentFormComponent = 'PkBankMethodForm';
              break;
            default:
              this.currentFormComponent = 'P2PDestinationData';
              break;
          }
        }
        break;
      case 'MULTIBANCO':
        this.currentFormComponent = 'MultibancoDestinationData';
        break;
      default:
        this.error = 'ERROR.METHOD_NOT_AVAILABLE';
    }
    this.startExpirationTimer();
  }

  submitPaymentData() {
    if (this.loading || this.redirecting) {
      return;
    }
    this.formComponent.validate();
    if (!this.isValid) {
      this.$toast.error(this.$t('ERROR.FORM_FIELDS') as string);
      return;
    }

    const elements = Array.from(this.dataForm) as HTMLInputElement[];
    let values = elements.reduce((acc: { [key: string]: string }, el: HTMLInputElement) => {
      if (el.name) {
        acc[el.name] = el.value;
      }

      if (this.formData.paymentMethod === 'QIWI' && el.name === 'msisdn') {
        acc['account'] = el.value;
      }
      if (this.formData.paymentMethod === 'CARD' && el.name === 'cvc') {
        if (el.value === '') {
          acc['cvc'] = '000';
        }
      }
      return acc;
    }, {});

    let request: FormSubmitRequest = {
      values: values,
      type: 'SUBMIT_DATA',
      browserInfo: this.collectBrowserInfo(),
      method: this.formData.paymentMethod,
      methodType: this.formData.serviceMethodType,
      additionalParams: <any>null
    };
    if (this.formData.token === 'test') {
      Log(request.values);
      if (request.values.cvc === '111') {
        this.formData.formStatus = 'PROCESSED';
        this.formData.token = this.formData.token + '_success';
        Log('cvc = 111');
        this.formDataChanged(this.formData);
        this.loading = false;
        this.redirecting = false;
      }

      if (request.values.cvc === '222') {
        this.formData.formStatus = 'FAILED';
        this.formData.token = this.formData.token + '_error';
        Log('cvc = 222');
        this.$emit('form-data-changed', this.formData);
      }

      if (request.values.cvc === '333') {
        this.formData.formStatus = 'EXPIRED';
        this.formData.token = this.formData.token + '_info';
        this.$emit('form-data-changed', this.formData);
        Log('cvc = 333');
      }

    } else {
      this.$emit('on-submit', this.formData.token, request);
    }
  }

  onPaymentTokenReceived(token: string, paymentMethod: PaymentMethodType) {
    this.formData.paymentMethod = paymentMethod;
    const paymentToken: { [key: string]: string } = {};
    paymentToken['paymentToken'] = token;
    let request: FormSubmitRequest = {
      values: paymentToken,
      type: 'SUBMIT_DATA',
      browserInfo: this.collectBrowserInfo(),
      method: this.formData.paymentMethod,
      methodType: this.formData.serviceMethodType,
      additionalParams: <any>null
    };

    if (this.formData.token === 'test') {
      this.formData.formStatus = 'PROCESSED';
      this.formData.token = this.formData.token + 'yapay_success';
      this.$emit('form-data-changed', this.formData);
    } else {
      this.$emit('on-submit', this.formData.token, request);
    }
  }

  collectBrowserInfo() {
    const screenWidth = window && window.screen ? window.screen.width : '';
    const screenHeight = window && window.screen ? window.screen.height : '';
    const windowWidth = document.body.clientWidth;
    const windowHeight = document.body.clientHeight;
    const colorDepth = window && window.screen ? window.screen.colorDepth : '24';
    const userAgent = window && window.navigator ? window.navigator.userAgent : '';
    const javaEnabled = (window && window.navigator ? navigator.javaEnabled() : false) || false;

    let language = '';
    if (window && window.navigator) {
      language = window.navigator.language ? window.navigator.language : (window.navigator as any).browserLanguage;
    }

    const d = new Date();
    let timeZoneOffset = d.getTimezoneOffset();

    if (!timeZoneOffset) {
      timeZoneOffset = 0;
    }

    return {
      colorDepth: colorDepth as string,
      javaEnabled: javaEnabled as any,
      language: language,
      screenHeight: screenHeight as string,
      screenWidth: screenWidth as string,
      timezone: String(timeZoneOffset) as string,
      userAgent: userAgent,
      windowHeight: windowHeight as any,
      windowWidth: windowWidth as any
    } as BrowserInfo;
  }

  submitForm() {
    this.dataFormSubmit.click();
  }

  protected validForm(valid: boolean) {
    this.isValid = valid;
  }

  protected setError(error?: string | undefined) {
    this.error = error || 'ERROR.COMMON';
  }

  getButtonHeight(): string | undefined {
    const styles = this.getButtonClasses();
    return styles?.height;
  }

  private getButtonClasses() {
    let payButtonElement = document.querySelector('#pay-button-id');
    if (payButtonElement) {
      return window.getComputedStyle(payButtonElement);
    }
    return null;
  }
}
</script>

<style lang="scss" scoped>
</style>
