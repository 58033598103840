var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    _vm._l(_vm.formData.additionalParams.fields, function (field) {
      return _c("div", { key: field.key }, [
        field.type === "FIRST_NAME" ||
        field.type === "LAST_NAME" ||
        field.type === "TEXT" ||
        field.type === "INFO"
          ? _c(
              "div",
              [
                _c(
                  "FormRow",
                  [
                    _c(
                      "FormCol",
                      [
                        _c(
                          "FormField",
                          [
                            _c("TextInputFormField", {
                              ref: "field.key",
                              refInFor: true,
                              attrs: {
                                inputName: field.key,
                                label: field.key,
                                regexp: field.regex,
                              },
                              on: {
                                changed: function ($event) {
                                  return _vm.fieldChanged(
                                    field.key,
                                    _vm.formFields[field.key]
                                  )
                                },
                              },
                              model: {
                                value: _vm.formFields[field.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formFields, field.key, $$v)
                                },
                                expression: "formFields[field.key]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        field.type === "PHONE"
          ? _c(
              "div",
              [
                _c(
                  "FormRow",
                  [
                    _c(
                      "FormCol",
                      [
                        _c(
                          "FormField",
                          [
                            _c("MobilePhoneInputFormField", {
                              ref: "field.key",
                              refInFor: true,
                              attrs: { inputName: field.key },
                              on: {
                                changed: function ($event) {
                                  return _vm.fieldChanged(
                                    field.key,
                                    _vm.formFields[field.key]
                                  )
                                },
                              },
                              model: {
                                value: _vm.formFields[field.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formFields, field.key, $$v)
                                },
                                expression: "formFields[field.key]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        field.type === "EMAIL"
          ? _c(
              "div",
              [
                _c(
                  "FormRow",
                  [
                    _c(
                      "FormCol",
                      [
                        _c(
                          "FormField",
                          [
                            _c("TextInputFormField", {
                              ref: "field.key",
                              refInFor: true,
                              attrs: {
                                inputName: field.key,
                                label: field.key,
                                regexp: field.regex,
                                min: 2,
                                max: 255,
                              },
                              on: {
                                changed: function ($event) {
                                  return _vm.fieldChanged(
                                    field.key,
                                    _vm.formFields[field.key]
                                  )
                                },
                              },
                              model: {
                                value: _vm.formFields[field.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formFields, field.key, $$v)
                                },
                                expression: "formFields[field.key]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        field.type === "CARD"
          ? _c(
              "div",
              [
                _c(
                  "FormRow",
                  [
                    _c(
                      "FormCol",
                      [
                        _c(
                          "FormField",
                          [
                            _c("CardInputFormField", {
                              ref: "field.key",
                              refInFor: true,
                              on: {
                                focusin: function ($event) {
                                  return _vm.sendEventToFocusIn($event)
                                },
                                focusout: function ($event) {
                                  return _vm.sendEventToFocusOut($event)
                                },
                                changed: function ($event) {
                                  return _vm.fieldChanged(
                                    field.key,
                                    _vm.formFields[field.key]
                                  )
                                },
                              },
                              model: {
                                value: _vm.formFields[field.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formFields, field.key, $$v)
                                },
                                expression: "formFields[field.key]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        field.type === "SECRET"
          ? _c(
              "div",
              [
                _c(
                  "FormRow",
                  [
                    _c(
                      "FormCol",
                      [
                        _c(
                          "FormField",
                          [
                            _c("SecretTextInputFormField", {
                              ref: "field.key",
                              refInFor: true,
                              attrs: { inputName: field.key },
                              on: {
                                changed: function ($event) {
                                  return _vm.fieldChanged(
                                    field.key,
                                    _vm.formFields[field.key]
                                  )
                                },
                              },
                              model: {
                                value: _vm.formFields[field.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formFields, field.key, $$v)
                                },
                                expression: "formFields[field.key]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        field.type === "NUMBER" || field.type === "AMOUNT"
          ? _c(
              "div",
              [
                _c(
                  "FormRow",
                  [
                    _c(
                      "FormCol",
                      [
                        _c(
                          "FormField",
                          [
                            _c("NumericInputFormField", {
                              ref: "field.key",
                              refInFor: true,
                              attrs: {
                                inputName: field.key,
                                label: field.key,
                                regexp: field.regex,
                                min: field.minAmount,
                                max: field.maxAmount,
                                required: field.required,
                              },
                              on: {
                                changed: function ($event) {
                                  return _vm.fieldChanged(
                                    field.key,
                                    _vm.formFields[field.key]
                                  )
                                },
                              },
                              model: {
                                value: _vm.formFields[field.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formFields, field.key, $$v)
                                },
                                expression: "formFields[field.key]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }