<template>
  <div class="payment-method-type-btn brand-secondary brand-secondary-hw brand-button-border-radius"
       @click="setSelected">
    <div class="payment-method-type-btn__label">{{ $t(additionalField.value) }}</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import AdditionalFieldBase = com.paidora.billing.types.models.core.additional.info.steps.AdditionalFieldBase;

@Component({})
export default class AdditionalStepSelectorForm extends Vue {
  @Prop()
  additionalField!: AdditionalFieldBase;

  @Emit('selector-selected')
  setSelected() {
    return this.additionalField;
  }
}
</script>

<style lang="scss">

</style>
