<template>
  <div class="card-input__wrapper brand-input-border-radius" v-bind:class="{
    invalid: invalid,
    'brand-error': invalid
    }">
    <div class="card-input__error">{{ $t(error) }}</div>
    <div class="card-input brand-secondary brand-input-border-radius" v-bind:class="{'brand-error': invalid}">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, {VNode} from 'vue';
import Component from 'vue-class-component';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';

@Component
export default class FormField extends Vue {
  field?: InputFormFieldBase<any> = {} as any;

  get invalid() {
    return this.field?.invalid;
  }

  get error() {
    return this.field?.error;
  }

  mounted() {
    this.field = (this.$slots.default as VNode[]).map(f => f.componentInstance as InputFormFieldBase<any>)
      .find(f => (f)?.isFieldInputComponent);
  }
}
</script>

<style scoped>

</style>

